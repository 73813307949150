var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    [
      _vm._v("\n  " + _vm._s(_vm.collection.name) + " "),
      _vm.auth.isSuperAdmin && !_vm.collection.enabled
        ? _c("span", [_vm._v("(disabled)")])
        : _vm._e(),
      _vm._v(" \n  "),
      _vm.auth.isGlobalAdmin
        ? _c(
            "gov-link",
            {
              attrs: {
                to: {
                  name: _vm.editCollectionRoute,
                  params: { collection: _vm.collection.id }
                }
              }
            },
            [_vm._v("\n    Edit\n  ")]
          )
        : _vm._e(),
      _c("br"),
      _vm.collection.order > 1
        ? _c(
            "gov-link",
            {
              on: {
                click: function($event) {
                  return _vm.$emit("move-up", _vm.collection)
                }
              }
            },
            [_vm._v("(Move up)")]
          )
        : _vm._e(),
      _vm.collection.order < _vm.collections.length
        ? _c(
            "gov-link",
            {
              on: {
                click: function($event) {
                  return _vm.$emit("move-down", _vm.collection)
                }
              }
            },
            [_vm._v("(Move down)")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }